import React, {lazy, Suspense} from 'react';
import './sass/main.scss';

import Loading from './components/loading';
//import HeaderPiscinas from './components/header-piscinas';
//import GridPiscinas from './components/grid-piscinas';
import GridSpa from './components/grid-camas-spas';
const Header = lazy(() => import('./components/header-camas-spas'));
//const Grid = lazy(() => import('./components/grid'))
const Revi = lazy(() => import('./components/revi'));

function App() {
  return (
      <div className="App">
      <Suspense fallback={<Loading />}>
      <Header />
      <div className='separador'>
        Elige relax, descanso y calidad
      </div>
      <GridSpa />
      <div className='separador footer'>
          <p>Con la garantía de INTEX</p>
      </div>
      <div className='footer-app'>
        <Revi />
        <p>&copy; INTEX Todos los derechos reservados</p>
      </div>
      </Suspense>
    </div>
  );
}

export default App;
