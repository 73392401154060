import React from "react";
import cama from '../images/colchon-hinchable.jpg';
import camaMedium from '../images/medium/colchon-hinchable.jpg';
import camaSmall from '../images/small/colchon-hinchable.jpg';
import spa from '../images/spa-hinchable.jpg';
import spaMedium from '../images/medium/spa-hinchable.jpg';
import spaSmall from '../images/small/spa-hinchable.jpg';

export default function Grid() {
    return (
        <div className='grid'>
            <div className='item'>
                <picture>
                    <source srcSet={spaSmall} media="(max-width: 420px)" />
                    <source srcSet={spaMedium} media="(max-width: 600px)" />
                    <img src={spa} className="responsiva" alt="Spas hinchables" width="360px" height="245px" />
                </picture>
                <div className='text'>
                    <h2 className='title'>Spas hinchables</h2>
                    <p>Ponle burbujas a tu día a día <br /> Y relájate en tu propio spa</p>
                    <ul>
                        <li>Sin instalación: hinchar y listo</li>
                        <li>Fácil mantenimiento del agua</li>
                        <li>Capacidad para hasta 6 personas</li>
                    </ul>
                    <div className='cont-button'>
                        <a className='btn-app' href='https://www.intex.es/spa-hinchables'>Comprar desde 459€</a>
                    </div>
                </div>
            </div>
            <div className='item'>
                <picture>
                    <source srcSet={camaSmall} media="(max-width: 420px)" />
                    <source srcSet={camaMedium} media="(max-width: 600px)" />
                    <img src={cama} className="responsiva" alt="Camas de aire hinchables" width="360px" height="245px" />
                </picture>
                <div className='text'>
                    <h2 className='title'>Camas de aire</h2>
                    <p>La solución para que recibir invitados <br /> en casa o ir de viaje no te quite el sueño.</p>
                    <ul>
                        <li>Disponible para 1 o 2 personas</li>
                        <li>Hinchado en menos de 3 minutos</li>
                        <li>Fácil de guardar y transportar</li>
                    </ul>
                    <div className='cont-button'>
                        <a className='btn-app' href='https://www.intex.es/colchones-hinchables/hogar'>Comprar desde 20,95€</a>
                    </div>
                </div>
            </div>
            
        </div>
    );
}